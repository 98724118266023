// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');
// @import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;800');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';
// Bootstrap and Bootswatch
// @import "~bootswatch/dist/slate/variables";
// @import "~bootstrap/scss/bootstrap";
// @import "~bootswatch/dist/slate/bootswatch";

// Fontawsome
$fa-font-path:        "../webfonts";
@import "~@fortawesome/fontawesome-free/scss/fontawesome";
@import "~@fortawesome/fontawesome-free/scss/solid";
@import "~@fortawesome/fontawesome-free/scss/brands";
@import "~@fortawesome/fontawesome-free/scss/regular";

// jQuery UI
// @import '~jquery-ui/themes/base/all.css';

// Animate on scroll
@import "~aos/dist/aos.css"
